.nav.navbar-nav > li:first-of-type > a,
.nav.navbar-nav > li:first-of-type > span{
        padding-left: 0;
}

.nav.navbar-nav > li:first-of-type a.btn {
        padding-left: 0.5rem;
}

.nav.navbar-nav.pull-right > li:last-of-type > a,
.nav.navbar-nav.pull-right > li:last-of-type > span{
        padding-right: 0;
}

.nav.navbar-nav.pull-right > li:last-of-type a.btn {
        padding-right: 0.5rem;
}

.pull-center .nav {
        float: none;
        display: block;
        text-align: center;
}

.pull-center .nav > li {
        float: none;
        display: inline-block;
}

#top-a-row .container{
        padding-top: 3px;
        padding-bottom: 3px;
}

#top-a-row ul.nav li.nav-item:last-child .nav-link,
#header-a-row ul.nav li.nav-item:last-child .nav-link{
        padding-right: 0;
}

#top-a-row  .nav>li>a, #top-a-row  .nav>li>a{
        font-size: 13px;
        display: flex;
        align-items: center;
}

#top-a-row  .nav-link i {
        font-size: 20px;margin-right: 10px;
}

#top-a-row  .nav-link{
        line-height: 20px;
}

.fix-to-top #header-a-row .container{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
}
