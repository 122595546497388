.slide-fullscreen.carousel-fade .carousel-caption {
        position: relative;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
}

.slide-fullscreen.carousel-fade .carousel-caption h1,
.slide-fullscreen.carousel-fade .carousel-caption a,
.slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p{
      /*  font-family: 'Raleway', sans-serif;*/
}

.slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p {
        font-size: 40px;
        line-height: 50px;
        padding: 5px 15px;
        margin-bottom: 0px;
        font-weight: 300;
        position: relative;

}

.opacity-filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
}

.slide-fullscreen.carousel-fade .carousel-caption .mod-intro-text p strong {
        font-weight: 700;
        font-size: 40px;
}

.slide-fullscreen .carousel-indicators {
        bottom: 60px;
}

.slide-fullscreen .item {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
}

.slide-fullscreen .yt-video {
        position: relative;
}

.slide-fullscreen .yt-video iframe {
        /*width: 100% !important;*/
        /*height: 100% !important;*/
}

.slide-fullscreen .yt-video:after,
.slide-fullscreen .mp4-video:after{
        background: #fff;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
}

.slide-fullscreen .yt-video.loaded:after,
.slide-fullscreen .mp4-video:after {
        opacity: 0;
}

.slide-fullscreen .mp4-video {
        width: 100%;
        height: 100%;
}

.slide-fullscreen .mp4-video video {
        position: fixed;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -100;
        transform: translateX(-50%) translateY(-50%);
        transition: 1s opacity;
}

.carousel-fade .carousel-inner .item {
        opacity: 0;
        transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
        opacity: 1;
}

.carousel-fade .carousel-control {
        z-index: 2;
}

.slide-fullscreen .custom-urls .btn{
    padding: 10px 40px;
    font-size: 18px;
    letter-spacing: 0px;
}

.slide-fullscreen .custom-urls .btn.btn-primary{
    color: #437ba5;
    background: #fff;
    font-weight: 700;
    border-radius: 10px;
    border: none;
}

.slide-fullscreen .custom-urls .btn.btn-primary:hover{
        color: #fff;
        background: #437ba5;
     
}

body .carousel-fade .custom-urls {
        margin: 60px 0 0;
}

/** fade animation **/
@media all and (transform-3d), (-webkit-transform-3d) {
        .carousel-fade .carousel-inner > .item.next,
        .carousel-fade .carousel-inner > .item.active.right {
                opacity: 0;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
        .carousel-fade .carousel-inner > .item.prev,
        .carousel-fade .carousel-inner > .item.active.left {
                opacity: 0;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
        .carousel-fade .carousel-inner > .item.next.left,
        .carousel-fade .carousel-inner > .item.prev.right,
        .carousel-fade .carousel-inner > .item.active {
                opacity: 1;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
        }
}

/** features **/
.showcase-features {
        margin-top: 150px;
        width: 100%;
}
