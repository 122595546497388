.footer-logos .items-block > .row > div:last-child .mod-item-image {
	margin-bottom: 0;
}

.about-activities h2.moduleTitle {
	margin-bottom: 50px;
}

.about-activities p.modulePretext {
	text-align: center;
	margin-bottom: 50px;
}

.about-activities .items-block.cards-list .card .card-text {
	padding: 0;
	border: none;
	margin: 0;
}

.about-activities .card-body ul {
	padding: 0;
	list-style: none;
}

.about-activities .card-body ul li {
	margin-bottom: 10px;
}

.about-activities .card-body ul li:before {
	font-family: 'FontAwesome';
	content: '\f101';
	margin-right: 5px;
	color: #7EA73F;
}

.mod-container-card .module-inner {
	border: 1px solid #437ba5;
	padding: 1.25rem;
}

.stuff .mod-item-title {
	margin-bottom: 20px;
}

.stuff .mod-intro-text.md-text p {
	margin-bottom: 5px;
}

.contacts_home_mod .icon-block {
    margin-bottom: 2.5rem;

}

.contacts_home_mod .icon-block a, .contacts_home_mod .icon-block i{
	    color: #437ba5;
}

.contacts_home_mod .icon-block i:before {
    font-size: 60px;
}

.contacts_home_mod .items-block .item:not(.last-xl-line-item) {
    border-right: 1px solid #dfdfdf;
}

.contacts_home_mod  .mod-item-heading.text-dark .mod-item-title {
    color: #454545;
}
