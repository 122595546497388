.more-line {
	width: 90%;
	position: relative;
}

.more-line:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 1px;
	background-color: #437ba5;
}

.items-block.services .item{
	margin-bottom: 60px;
}

.items-block.services .btn.btn-link{
	color: #454545;
	font-weight: 600;
}

.items-block.services .mod-item-image{
	position: relative;
}

.mod-image-link-hover{
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0;
	font-size: 10px;
	line-height: 16px;
}

.items-block.services .mod-item-image:hover .mod-image-link-hover{
	opacity: 1;
	font-size: 14px;
	line-height: 16px;
}

.mod-image-link-hover:hover{
	text-decoration: none;
}

.products_mod .items-block.services .mod-item-button {
	display: block;
	width: 30%;
}
.products_mod .items-block.services .mod-item-button a {
	display: block;
	padding: 5px;
	background-color: #fff;
	border: 2px solid #437ba5;
	border-radius: 10px;
}
.products_mod .items-block.services .mod-item-button a:hover {
	background-color: #437ba5;
	border: 2px solid #fff;
	color: #fff;
	text-decoration: none;
}