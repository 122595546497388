.nav ul li {
        list-style: none;
        font-size: 0.8rem;
}

.navbar-nav>li>span,
.navbar-nav>li>a{
        padding: 0;
        display: block;
        position: relative;
        line-height: 1.5rem;
        padding: 0.3rem 0.5rem;
        border: 2px solid transparent;
}

.nav>li>a,
.nav>li>a {
        color: #666;
        font-size: 0.9rem;
}

/*.home_page */section.header .nav>li>a,
/*.home_page */section.header .nav>li>span {
        color: #fff;
}

section.header .nav>li>a.btn {
        margin-left: 1.2rem;
}

/*section.header.fix-to-top.nonzero-position .nav>li>a,
section.header.fix-to-top.nonzero-position .nav>li>span,
body.spacer-top section.header.fix-to-top .nav>li>a,
body.spacer-top section.header.fix-to-top .nav>li>span {
        color: #666;
        font-size: 0.8rem;
        padding: 0.5em 0.7em;
}*/

.nav>li.active>a,
.nav>li.active>span {
        /*color: */
        font-weight: 700;
}

/*.nav>li>a:focus,*/
/*.nav>li>a:hover {*/
/*        background: none;*/
/*}*/

.nav>li.deeper>a:after,
.nav>li.deeper>span:after {
        font-family: 'FontAwesome';
        content: '\f107';
        float: right;
        line-height: 20px;
        margin-left: 5px;
}

body.msie .nav>li.deeper>a:after,
body.msie .nav>li.deeper>span:after {
        float: none;
}

.nav li.deeper > ul.nav-child {
        display: none;
        /*opacity: 0;*/
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        width: 250px;
        background: #f5f5f5;
        padding: 0;
        z-index: 99999;
}

.nav li.deeper > ul.nav-child li a,
.nav li.deeper > ul.nav-child li span {
        padding: 13px 20px;
        display: block;
        color: #545454 !important;
        border-bottom: 1px solid #eaeaea;
}

.nav li.deeper ul.nav-child li.deeper > a:after,
.nav li.deeper ul.nav-child li.deeper > span:after {
        font-family: 'FontAwesome';
        content: '\f105';
        /*float: right;*/
        margin-left: 15px;
}

.nav li.deeper > ul.nav-child > li:hover a,
.nav li.deeper > ul.nav-child > li.active > a{
        background: #2E6BA9;
        color: #fff ;
}

.nav li.active > a {
        /*color: #2E6BA9 !important;*/
}

.nav li.deeper > ul.nav-child a,
.nav li.deeper > ul.nav-child span {
        color: #333;
}

.nav li.deeper  ul.nav-child .nav-child a {
        background: transparent !important;
        color: #545454;
}

.nav li.deeper  ul.nav-child .nav-item:hover > a,
.nav li.deeper.active.parent li.deeper.active.parent > a.nav-link {
        color: #fff !important;
}
.nav li.deeper.active.parent li.deeper.active.parent .current.active > a {
        background: #2E6BA9 !important;
        color: #fff !important;
}
.nav li.deeper  ul.nav-child .nav-child .nav-item:hover {
        background: #2E6BA9 !important;
        color: #fff;
}
.nav.horizontal>li>a.nav-link {
        padding: 0.5em 1.2em;
}

/*.nav.horizontal>li:first-child>a {*/
/*        padding-left: 0;*/
/*}*/
/**/
/*.nav.horizontal>li:last-child>a {*/
/*        padding-right: 0;*/
/*}*/

.nav.horizontal li.deeper {
        position: relative;
}

.nav.horizontal li.deeper:hover > ul.nav-child {
        display: block;
        /*opacity: 1;*/
        -webkit-animation: fadeInDown 0.3s;
        animation: fadeInDown 0.3s;
}

.nav.horizontal li.deeper ul.nav-child {
        position: absolute;
        top: 38px;
        right: 0;
}

/*.home_page */section.header.fix-to-top.nonzero-position .nav.horizontal li.deeper ul.nav-child {
        /* top: 37px; */
}

.nav.horizontal>li.deeper>ul.nav-child ul.nav-child {
        top: -15px;
        left: 250px;
        border-left: 2px solid #eee;
}

.main_menu_mod .btn-warning {
        color: #fff !important;
}

.main_menu_mod .btn-warning:hover {
        color: #fff !important;
        background: #e8690f;
}

