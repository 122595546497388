.logo a {
        line-height: 1.5rem;
        display: block;
}

.logo .logo_title {
        font-size: 0;
        display: block;
        width: 0;
        height: 0;
}

.logo-module > .pull-right {
        width: 100%;
}

.logo h1,
.logo h2,
.logo h3,
.logo h4,
.logo h5 {
        margin: 0;
}

.logo_orig{
        display: block;

}

.logo-link img {
        float: left;
}

#footer-b-row .footer-logo {
        align-items: center;
        display: flex;
}