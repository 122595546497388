#footer-a-row h2.moduleTitle {
	margin-bottom: 60px;
}

.subscribe .introtext {
	margin-bottom: 70px;
	font-weight: 300;
	font-size: 1.8rem;
}

.subscribe .input-group-addon {
	color: #fff;
	background: none;
	border: none;
	border-bottom: 1px solid #fff;
	padding-right: 0;
	text-align: right;
	width: 90px;
	font-size: 30px;
}

.subscribe .input-group-addon i {
	display: block;
	margin-top: -4px;
}

.subscribe .form-control {
	width: 100% !important;
	background: none;
	border: none;
	border-bottom: 1px solid #fff;
	text-align: left;
	color: #fff;
	font-size: 1.4rem;
	padding: 0.7em 0.75em;
	/*font-weight: 100;*/
}

.subscribe .btn.btn-primary {
	background: none;
	border-color: #fff;
	color: #fff;
	font-size: 1.3rem;
	padding: 0.8em 1.2em;
	line-height: 24px;
}