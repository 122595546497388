.subscribe .pretext {
        margin: 15px 0 20px;
}

.subscribe .pretext.md-text {
        font-size: 1.1rem;
}

.subscribe form.form-inline .form-control {
        width: 400px;
        border-color: #01a3f8;
        border-right: none;
}

/** errors **/
.subscribe .mail-error-box {
        color: #e21a22;
        margin-top: 5px;
}
