button#dropdownLangs {
	padding: 6px 0;
	background: none;
	border: none;
}

button#dropdownLangs .fa-angle-down {
	font-size: 11px;
}

.langs-dropdown.dropdown .dropdown-menu a,
.langs-dropdown.dropdown .dropdown-menu button {
	font-size: 12px;
	line-height: 18px;
	padding: 5px 10px;
}

.langs-dropdown.dropdown .dropdown-menu {
	min-width: 50px;
	padding: 2px 0;
}
