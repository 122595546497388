a.map-info {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: #fff;
        color: #7EA73F !important;
        border-radius: 100%;
        font-size: 1.2rem;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

a.map-info:hover {
        cursor: pointer;
}