ol.breadcrumb {
        padding: 0;
        background: none;
        margin-bottom: 0;
}

ol.breadcrumb li,
ol.breadcrumb li b {
        font-weight: 300;
        font-size: 0.8rem;
}

.breadcrumbs .module-inner {
	padding-bottom: 15px;
	border-bottom: 1px solid #dddddd;
}

.breadcrumb .breadcrumb-item{
	color: #6c757d;
}

.breadcrumb .breadcrumb-item a{
	color: #437ba5;
}

