.carousel-control.left,
.carousel-control.right {
        background: none;
        font-size: 40px;
        bottom: auto;
        top: 50%;
        margin-top: -20px;
        width: 80px;
        opacity: 0.7;
}

.carousel-control.left:hover,
.carousel-control.right:hover {
        opacity: 0.9;
}

/*.carousel-container {
        background: rgba(15,14,14,0.5);
}*/

.carousel-caption .mod-item-heading {
        margin-top: 0;
        font-weight: 300;
}

.carousel-caption .mod-item-heading b {
        font-weight: 500;
}

.carousel-caption .mod-intro-text {
        margin-bottom: 0;
}

.carousel-indicators {
        bottom: 0;
        line-height: 1.2rem;
        width: 100%;
        left: 0;
        margin: 0;
        padding: 0;
}

body.msie .caption-contain .mt-auto {
        margin-top: 254px !important;
}
