.nav.navbar-nav > li:first-of-type > a,
.nav.navbar-nav > li:first-of-type > span{
        padding-left: 0;
}

.nav.navbar-nav > li:first-of-type a.btn {
        padding-left: 0.5rem;
}

.nav.navbar-nav.pull-right > li:last-of-type > a,
.nav.navbar-nav.pull-right > li:last-of-type > span{
        padding-right: 0;
}

.nav.navbar-nav.pull-right > li:last-of-type a.btn {
        padding-right: 0.5rem;
}

.nav.pull-center {
        float: none;
        display: block;
        text-align: center;
}

.nav.pull-center > li {
        float: none;
        display: inline-block;
}

.fixtoscrolltop-section {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        -webkit-transition: background .1s ease-out;
        -moz-transition: background .1s ease-out;
        -o-transition: background .1s ease-out;
        transition: background .1s ease-out;
}

.fixtoscrolltop-section.scrolled {
        background: rgba(255,255,255,1);
        box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
}