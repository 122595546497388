.nav-vertical.flex-column > li {
        padding-bottom: 15px;
}

.sidebar .nav-vertical.flex-column > li {
        padding-bottom: 25px;
}

.sidebar .nav>li>a,
.sidebar .nav>li>span {
        text-transform: none !important;
        display: block;
}

.sidebar .nav>li>a:after {
        font-family: "FontAwesome";
        float: right;
        width: 20px;
        text-align: center;
        font-size: 16px;
}

.sidebar .nav>li>a.home-link:after {
        content: '\f015';
}

.sidebar .nav>li>a.aboutus-link:after {
        content: '\f129';
}

.sidebar .nav>li>a.products-link:after {
        content: '\f085';
}

.sidebar .nav>li>a.practice-link:after {
        content: '\f085';
}

.sidebar .nav>li>a.resources-link:after {
        content: '\f008';
}

.sidebar .nav>li>a.events-link:after {
        content: '\f274';
}

.sidebar .nav>li>a.contactus-link:after {
        content: '\f2ba';
}

.sidebar .nav>li>a.gallery-link:after{
    content: '\f03e';
}

.sidebar .menu-modal.text-dark.btn{
    color: #fff !important;
}

.nav.flex-column>li>a {
        display: block;
        line-height: 1.2rem;
}

#aside-right .nav>li.deeper>a:after,
#aside-right .nav>li.deeper>span:after {
        display: none;
}

#aside-right .nav li.deeper > ul.nav-child {
        display: block;
        width: 100%;
}

#aside-right .nav-vertical >li {
        margin-bottom: 15px;
}

#aside-right .nav-vertical >li >span {
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 15px;
        display: block;
}

#aside-right .nav-vertical >li >span:before {
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        font-size: 2rem;
        margin-right: 10px;
}

#aside-right .nav-vertical >li >span.pramonei:before {
        content: "\e902";
}

#aside-right .nav-vertical >li >span.pastatams:before {
        content: "\e901";
}

#aside-right .nav-vertical >li >span.laivams:before {
        content: "\e900";
}

#aside-right .nav-vertical >li >span.talpos:before {
        content: "\e903";
}

#aside-right .nav li.deeper > ul.nav-child {
        background: none;
}

#aside-right .nav li.deeper > ul.nav-child li a,
#aside-right .nav li.deeper > ul.nav-child li span {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 8px;
        padding-top: 8px;
        border: none;
        transition: none;
}

#aside-right .nav li.deeper > ul.nav-child > li:hover a {
        background: none;
        color: #545454 !important;
}

#aside-right .nav li.deeper > ul.nav-child > li:hover > a,
#aside-right .nav li.deeper > ul.nav-child > li.active > a {
        background: none;
        font-weight: 700;
        color: #545454 !important;
}

#aside-right .nav ul.nav-child > li.deeper > ul.nav-child {
        display: none;
        padding-left: 15px;
}

#aside-right .nav ul.nav-child > li.deeper > ul.nav-child > li {
        padding-left: 10px;
        border-left: 2px solid #2E6BA9;
}

#aside-right .nav ul.nav-child > li.deeper > ul.nav-child > li > a,
#aside-right .nav ul.nav-child > li.deeper > ul.nav-child > li > span {
        font-size: 0.8rem;
}

#aside-right .nav ul.nav-child > li.deeper.active > ul.nav-child {
        display: block;
}

#aside-right .nav li.deeper ul.nav-child li.deeper > a:after,
#aside-right .nav li.deeper ul.nav-child li.deeper > span:after {
        display: none;
}

#aside-right .section-container {
        padding: 15px 15px 0 15px;
        border: 1px solid #f1f1f1;
}


#aside-right .nav-vertical>li {
    padding-bottom: 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
    transition: all .3s;
}

#aside-right .nav>li.active,
#aside-right .nav>li:hover{
    border-bottom: 1px solid #437ba5;
    transition: all .3s;
}

#aside-right .nav-vertical>li:last-child{
        border-bottom: none;
}

#aside-right .nav>li>a {
    font-size: .875rem;
    font-weight: 400;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    transition: all .3s;
}

#aside-right .nav>li.active>a{
        font-weight: 600;
}

/*#aside-right .nav>li>a:after{
        font-family: "FontAwesome";
        content: '\f105';
        position: absolute;
        right: 10px;
       transition: all .3s;
}

#aside-right .nav>li>a:hover:after,
#aside-right .nav>li.active>a:after{
        right: 0;
        transition: all .3s;
}*/

#aside-right .nav>li>a:hover{
        text-decoration: none;
}

section.footer .nav-vertical .nav-link{
    padding-left: 0;
}

section.footer .nav-vertical.flex-column > li{
    padding-bottom: 7px;
}

#footer-b-row .tech-menu .moduleTitle,
#footer-b-row .article-menu .moduleTitle {
        font-size: 16px;
        margin-bottom: 30px;
}
#footer-b-row .tech-menu .nav-vertical a,
#footer-b-row .article-menu .nav-vertical a {
        color: #fff;
        margin-bottom: 15px;
        font-weight: 400;
        letter-spacing: 1px;
}
