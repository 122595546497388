.mod_its_form .form-control {
        border-width: 1px;
        padding: 0.4rem 0.75rem;
        height: auto;
        color: #777;
}

.mod_its_form textarea {
        height: 100px !important;
}

.mod_its_form mark {
        display: none !important;
}

.mod_its_form .form-control.error {
        border: 1px solid #d25548;
}

.mod_its_form_message > span {
	display: none;
	margin-bottom: 10px;
}

.mod_its_form_message.s span.s {
	display: block;
	color: #28a745;
}

.mod_its_form_message.e span.e {
	display: block;
	color: #dc3545;
}

.mod_its_form_message.c span.c {
	display: block;
	color: #dc3545;
}

.mod_its_form .control-group-button .btn {
	padding: 6px 40px;
	font-weight: 600;
}

#main-top-a-row .fill .moduleTitle {
	margin-bottom: 20px;
}

#main-top-a-row .fill .pretext{
	margin-bottom: 20px;
	text-align: center;
}