.items-block.cards-list .card h3 {
        border-bottom: 1px solid #D9D9D9;
        padding-bottom: 15px;
        margin-bottom: 10px;
}

.aboutus_mod  .card{
	background: none;
}

.aboutus_mod .moduleTitle,
.aboutus_mod .mod-item-heading.card-title,
.aboutus_mod  .mod-intro-text{
	color: #fff;
}

.aboutus_mod h1.line:after, .aboutus_mod h2.line:after,  .aboutus_mod h3.line:after{
	background: #fff;
}

.aboutus_mod .items-block.cards-list .card .card-text{
	border: none;
}

.aboutus_mod .items-block .card:hover{
	box-shadow: none;
}

.aboutus_mod .mod-intro-text p{
	font-size: 18px;
    line-height: 30px;
    letter-spacing: 2px;
}
