.pull-center .items-block > .row {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        justify-content: center;
        -webkit-justify-content: center;
}

.mod-item-image {
        margin-bottom: 20px;
}

.mod-intro-text {
        margin-bottom: 20px;
}

.pretext-line .modulePretext {
	padding-bottom: 15px;
	position: relative;
}

.pretext-line .modulePretext:after {
	content: '';
	background: #2E6BA9;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 40px;
	height: 2px;
}
