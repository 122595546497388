.footer-multi-col {
	min-width: 460px;
}

.footer-multi-col .module-inner,
.footer-multi-col .module-content {
	height: 100%;
}

.footer-multi-col .nav.horizontal>li>a.nav-link {
	padding: 0 0.5em;
	line-height: 0.9rem;
}

.footer-multi-col .nav.horizontal>li>a.nav-link {
	border-right: 1px solid #fff;
}

.footer-multi-col .nav.horizontal>li:first-child>a.nav-link {
	padding-left: 0;
}

.footer-multi-col .nav.horizontal>li:last-child>a.nav-link {
	padding-right: 0;
	border-right: none;
}
