.copyright-text {
        font-size: 0.8rem;
}
#footer-b-row .article-menu a.art-link{
        line-height: 1.2;
        text-transform: uppercase;
        color: #f8f9fa !important;
        font-weight: 700 !important;
        font-size: 16px;
}
#footer-b-row .article-menu a.art-link:hover {
        text-decoration: underline;
}