.showcase .module-content .page-header .filter {
    background: rgba(10,5,1,0.5);
}

.showcase .module-content .page-header .page-header-title {
    min-height: 250px;
}

.showcase .module-content .page-header .page-header-title h1,
.showcase .module-content .page-header .page-header-title h2 {
    font-weight: 300;
    position: relative;
    z-index: 10;
    font-size: 36px;
    line-height: 50px;
    padding: 5px 15px;
}

body.msie .showcase .module-content .page-header .page-header-title h1,
body.msie .showcase .module-content .page-header .page-header-title h2 {
   margin-top: 93px !important;
}

.showcase .module-content .page-header .page-header-title h1 b,
.showcase .module-content .page-header .page-header-title h2 b{
    font-weight: 700;
}

.showcase .module-content .page-header .page-header-title h1.line:after,
.showcase .module-content .page-header .page-header-title h2.line:after{
    background-color: #fff;
    height: 2px;
}



.showcase .module-content .page-header .page-header-title h1 span {
    display: block;
    position: relative;
    margin-bottom: 15px;
    padding: 5px 15px;
}

.showcase .module-content .page-header .page-header-title h1 span:last-child {
    display: inline-block;
    position: relative;
    padding: 5px 15px;
}
