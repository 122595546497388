#its-menu-row {
        display: none;
}

.its_menu {
        position: absolute;
}

.its_menu .its_menu_inner {
        box-shadow: 0 2px 2px rgba(0,0,0,0.2);
}

.its_menu .its_menu_inner {
        margin: 0 15px;
        padding: 15px;
        background: #fff;
}

.main_menu_mod a.nav-link {
        font-weight: 700;
}

.main_menu_mod .menu-modal.btn {
        background-color: #437ba5;
}