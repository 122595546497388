.split h2 {
	font-weight: 700;
	margin-bottom: 30px;
	padding-bottom: 15px;
	position: relative;
}

.split h2:after {
	content: '';
	width: 50px;
	height: 3px;
	background-color: #fff;
	position: absolute;
	bottom: 0;
	left: 0;
}

.split p {
	line-height: 2.5rem;
}

.aboutus_mod .split .custom-urls .btn {
	background-color: #fff;
	color: #686868;
	font-weight: 700;
	border-radius: 10px;
	padding-right: 30px;
    padding-left: 30px;
}

.about-us-mod p{
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
}